import React from 'react';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import Icon from "@mui/material/Icon";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import HttpsIcon from '@mui/icons-material/Https';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ShareIcon from '@mui/icons-material/Share';
import ReportIcon from '@mui/icons-material/Report';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import MailIcon from '@mui/icons-material/Mail';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

export const handleBoardConfig = (data) => {

  let config = data['mboard'];

  if(!config){
    return {
      type: 'notInitialized',
    };
  }

  const keys = Object.keys(data);

  keys.forEach((key) => {
    if(key.indexOf('sendToOutside') !== -1){
      if(typeof config['email'] === 'undefined'){
        config.email = {}
      }
      config.email[key.replace('dito.project.sendToOutside.', '')] = data[key];
    }
    if(key.indexOf('dito.project.geo') !== -1){
      if(typeof config.resource.topic['map'] === 'undefined'){
        config.resource.topic.map = {}
      }
      config.resource.topic.map[key.replace('dito.project.geo.', '').split('.').join()] = data[key];
      if(typeof config.resource.article['map'] === 'undefined'){
        config.resource.article.map = {}
      }
      config.resource.article.map[key.replace('dito.project.geo.', '').split('.').join()] = data[key];
    }
  })

  return config;
}

export const checkMainConfigValue = (config, attr, expectedValue) => {
  return config && config[attr] === expectedValue
}

export const getMainConfigValue = (config, attr) => {
  return config[attr];
}

export const checkConfigAttributeValue = (config, part, attr, expectedValue) => {
  return config && config[part] && config[part][attr] === expectedValue;
}

export const getConfigAttributeValue = (config, part, attr) => {
  return config && config[part] && config[part][attr] ? config[part][attr] : null;
}

export const checkResourceConfigAttributeValue = (config, type, attr, expectedValue, subAttr) => {
  if(subAttr){
    return config && config.resource && config.resource[type] && config.resource[type][attr] && config.resource[type][attr][subAttr] === expectedValue;
  }
  return config && config.resource && config.resource[type] && config.resource[type][attr] === expectedValue;
}

export const getInitalModerationTabValue = (config) => {
  if(config && config.moderation && !config.moderation.comments){
    if(config && config.moderation && !config.moderation.internalComments){
      return 2
    }
    return 1;
  }
  return 0;
};

export const displayAsFA = (type, config, color = 'action') => {
  const fontAwesome = config && config.fontAwesome;
  if(fontAwesome){
    switch (type) {
      case 'article':
        return <Icon color={color} baseClassName="far" className="fa-comment"  />
      case 'visibility':
        return <Icon color={color} baseClassName="far" className="fa-eye"  />
      case 'visibilityOff':
        return <Icon color={color} baseClassName="far" className="fa-eye-slash"  />
      case 'locked':
        return <Icon color={color} baseClassName="far" className="fa-lock"  />
      case 'unlocked':
        return <Icon color={color} baseClassName="far" className="fa-lock-open"  />
      case 'trash':
        return <Icon color={color} baseClassName="fas" className="fa-trash"  />
      case 'user':
        return <Icon color={color} baseClassName="fas" className="fa-user"  />
      case 'userActive':
        return <Icon color={color} baseClassName="fas" className="fa-user-check"  />
      case 'userNotActive':
        return <Icon color={color} baseClassName="fas" className="fa-user-check"  />
      case 'edit':
        return <Icon color={color} baseClassName="fas" className="fa-pen"  />
      case 'creationDate':
        return <Icon color={color} baseClassName="fas" className="fa-file-medical"  />
      case 'modificationDate':
        return <Icon color={color} baseClassName="fas" className="fa-edit"  />
      case 'topic':
        return <Icon color={color} baseClassName="far" className="fa-lightbulb" />
      case 'share':
        return <Icon color={color} baseClassName="fas" className="fa-share-alt" />
      case 'markedAsAbuse':
        return <Icon color={color} baseClassName="fas" className="fa-bell" />
      case 'notMarkedAsAbuse':
        return <Icon color={color} baseClassName="fas" className="fa-bell-slash" />
      case 'mail':
        return <Icon color={color} baseClassName="far" className="fa-envelope" />
      case 'warn':
        return <Icon color={color} baseClassName="fas" className="fa-exclamation-triangle" />
      default:
        return '';
    }
  }
  switch (type) {
    case 'article':
      return <CommentIcon color="secondary" />
    case 'visibility':
      return <VisibilityIcon color={color} />;
    case 'visibilityOff':
      return <VisibilityOffIcon color={color} />;
    case 'locked':
      return <HttpsIcon color={color} />;
    case 'unlocked':
      return <LockOpenIcon color={color} />;
    case 'trash':
      return <DeleteIcon color={color} />;
    case 'user':
      return <PersonIcon color={color} />;
    case 'userActive':
      return <HowToRegIcon color={color} />;
    case 'userNotActive':
      return <PersonOutlineIcon color={color} />;
    case 'creationDate':
      return <NoteAddIcon color={color} />;
    case 'share':
      return <ShareIcon color={color} />;
    case 'edit':
    case 'modificationDate':
      return <EditIcon color={color} />;
    case 'topic':
      return <LightbulbOutlinedIcon color={color} />;
    case 'markedAsAbuse':
      return <ReportOffIcon color={color} />;
    case 'notMarkedAsAbuse':
      return <ReportIcon color={color} />;
    case 'mail':
      return <MailIcon color={color} />;
    case 'warn':
      return <WarningAmberIcon color={color} />;
    default:
      return '';
  }
}

export  const checkColumnConfig = (columnName, config, bool) => {
  return config && config.resource && config.resource[config.type] && config.resource[config.type][columnName] || (bool ? false : 'excluded');
};

export const handleFileAccpet = (config) => {
  return 'image/*,audio/*,application/*,text/*'
}